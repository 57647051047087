import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpiringTransaction } from 'src/app/utility/model/finance-transactions-response';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';
import { LangService } from 'src/app/services/lang.service';
import { AuthData } from '../../utility/model/token-auth';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.scss'],
})
export class PointComponent implements OnInit {
  authData: Partial<AuthData> = {};
  @Input() flexDollars: number | null | undefined;
  @Input() flexDollarsEarned: number | null | undefined;
  @Input() flexDollarsRedeemed: number | null | undefined;
  @Input() loading = false;
  @Input() showMoreDetails = true;
  @Output() toggleDrawer = new EventEmitter();
  @Output() reload = new EventEmitter();
  @Output() syncBayerPoints = new EventEmitter();
  pointsExpiringThisMonth: ExpiringTransaction = {};
  start = dayjs().startOf('year').format('YYYY-MM-DD');
  end = dayjs().format('YYYY-MM-DD');
  @Input() set expiringTransactions(value: ExpiringTransaction[] | null) {
    const monthEnd = dayjs().endOf('month').format('YYYY-MM-DD');
    const yearEnd = dayjs().endOf('year').format('YYYY-MM-DD');
    this.pointsExpiringThisMonth = value.find((item: ExpiringTransaction) =>
      this.showYearlyExpiringPoints
        ? item.expiry_date === yearEnd
        : item.expiry_date === monthEnd,
    ) || { expiry_date: monthEnd, points: 0 };
  }
  showYearlyExpiringPoints = environment.settings.showYearlyExpiringPoints;
  isBayer: boolean = environment.settings.app === 'bayer';
  showPointsOnHome = environment.settings.showPointsOnHome;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    public lang: LangService,
  ) {}

  ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
  }

  get expiryPoint() {
    return (
      environment.settings.showExpiringPoints ||
      !!Number(
        this.authData?.tokenAuth?.organization_settings
          ?.points_expiry_frequency ?? 0,
      )
    );
  }
}
