export default {
  dev: {
    admin: 'https://rishi.skordev.com/admin-dashboard/#/report',
    app: 'https://rishi.skordev.com/',
    basePath: 'https://pru-rewards.skordev.com/#/',
    img: 'https://rishi.skordev.com',
  },
  prod: {
    admin: 'https://cerrapoints.com/admin-dashboard/#/report',
    app: 'https://cerrapoints.com/',
    basePath: 'https://prurewards.prudential.com.sg/#/',
    img: 'https://cerrapoints.com',
  },
};
